<template>
  <div class="px-2 stock-advisor">
    <b-row class="m-0 main-section mb-1">
      <b-col cols="12" class="tab-card">
        <b-tabs  class="add-modal" >
          <b-tab
            v-for="item in mainTab"
            :key="'dyn-tab-' + item.id"
            :title="item.title"
            @click="tab = item.title"
          >
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
    <b-row class="m-0" v-if="tab == 'Packages'">
      <b-col cols="12" md="3" >
        <b-card class="p-0 package-filter-card">
          <div class="mb-3">
            <p class="title mb-1">Advisor</p>
            <b-input-group>
              <b-input-group-prepend>
                <span class="input-group-text"> <feather-icon
                  icon="SearchIcon"
                  size="20"
                /></span>
              </b-input-group-prepend>
              <b-form-input v-model="search" size="lg" placeholder="Search">
              </b-form-input>
            </b-input-group>
            <div v-for="(key, value) in filters.Advisors" :key="value" class="d-flex align-items-center mt-1">
              <b-form-radio
                v-model="selectedAdvisor"
                :value="value"
                name="is-menu-visible"
                class="mr-0"
                inline
              ><span class="font-weight-bold">{{ value }}</span></b-form-radio>
            </div>
          </div>
            
          <div v-if="filters.Segment" class="mb-3">
            <p class="title">Segment</p>
            <div v-for="(key, value) in filters.Segment" :key="value" class="d-flex align-items-center mt-1">
              <b-form-radio
                v-model="selectedSegment"
                :value="value"
                name="is-menu-visible"
                class="mr-0"
                inline
              />
              <span class="font-weight-bold">{{ value == 'fno' ? 'F&O' : value == 'equity' ? 'Equity' : value == 'commodity' ? "Commodity" : null }}</span>
              
            </div>
          </div>
        </b-card>
      </b-col>
      <b-col cols="12" md="9" class="tab-card">
        <b-row class="m-0">
          <b-col
            lg="12"
            xl="6"
            v-for="(tabValue, index) in packages"
            :key="index"
          >
            <div>
              <b-card class="p-0 packages-card">
                <b-card-header class="h1 d-flex">
                  <b-card-title
                    class="font-weight-bolder h1 d-md-flex d-sm-block justify-content-between w-100"
                  >
                    <div class="d-flex">
                      <b-img v-if="tabValue.advisorAvatar" v-bind="{width: 40, height: 40}" :src="tabValue.advisorAvatar" rounded="circle" alt="Circle image"></b-img>
                      <img v-else src="@/assets/images/package_logo.png" alt="logo" width="45" height="45"/>
                      <div class="ml-1">
                        <p class="m-0 text-break main-title">{{ tabValue.title }}</p>
                        <p class="m-0 sub-title" v-if="tabValue.subTitle">{{ tabValue.subTitle }}</p>
                      </div>
                    </div>
                    <div class="mt-md-0 mt-2 d-flex align-items-start justify-content-end div-badge">
                      <b-badge
                        :variant="'light-primary'"
                        class="text-capitalize active-status mr-1 badge-segment"
                        >
                        {{ tabValue.segment == 'fno' ? 'F&O' : tabValue.segment == 'equity' ? 'Equity' : "Commodity" }}
                      </b-badge>
                      <b-badge
                        :variant="'light-primary'"
                        :class="tabValue.riskProfile == 'HIGH' ? 'badge-light-danger' : tabValue.riskProfile ==  'MEDIUM' ? 'badge-light-warning' : 'badge-light-success'"
                        class="active-status"
                        v-if="tabValue.riskProfile"
                      >
                        {{ tabValue.riskProfile }}
                      </b-badge>
                    </div>

                  </b-card-title>
                  <div class="d-flex align-items-center"></div>
                </b-card-header>
                <b-card-body class="packages-card-body" >
                  <div class="d-md-flex d-sm-block justify-content-between mb-1 ">
                    <div class="details-section">
                      <p class="mb-0 font-base">
                        Minimum Capital:
                      </p>
                      <span class="font-weight-bolder" style="color:#252F4A;">₹ {{ numberFormat(tabValue.minCapital) }}</span>
                    </div>
                    <div class="details-section-gap"></div>
                    <div class="details-section">
                      <p class="mb-0 font-base">
                        Advisor by:
                      </p>
                      <span class="font-weight-bolder" style="color:#252F4A;">{{ tabValue.advisorName }}</span>
                    </div>
                    <div class="details-section-gap"></div>
                    <div class="details-section">
                      <p class="mb-0 font-base">
                        Frequency:
                      </p>
                      <span class="font-weight-bolder" style="color:#252F4A;">{{ tabValue.frequency }}</span>
                    </div>
                  </div>
                  <hr>
                  <div class="mb-1">
                    <ul style="color: #252F4A; padding-left: 20px;">
                      <div v-for="(feature, index) in tabValue.features" :key="index" >
                        <li v-if="index<=featuresLength">{{ feature }}</li>
                      </div>
                      <span class="show-less-more"  v-if="(tabValue.features).length > featuresLength" @click="featuresLength=tabValue.features.length ">
                        Read more <feather-icon
                        icon="ChevronDownIcon"
                        size="16"
                      />
                      </span>
                      <span  class="show-less-more" v-if="(tabValue.features).length <= featuresLength" @click="featuresLength=4 ">
                        Show Less <feather-icon
                        icon="ChevronUpIcon"
                        size="16"
                      />
                      </span>
                    </ul>
                  </div>

                  <VueSlickCarousel  class="carousel-container" v-bind="sliderSetting">
                    <template
                      slot="prevArrow"
                      slot-scope="{prev}"
                    >
                    
                      <div class="custom-navigation" @click="prev">
                        <div class="navigation">
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="20"
                          />
                        </div>
                      </div>
                    </template>
                    <div class="item" v-for="data,j in tabValue.payOptions" :key="j" :slidesToShow="3">
                      <input class="hidden" v-model="buyPackage" :value="data.url" name="package" type="radio" :id="data.url">
                      <label @click="buyPackage = data.url" :for="data.url" :class="{'active-item' : buyPackage === data.url}">
                        <p class="mb-0 font-base"> {{ data.title.replace("_", " ") }}</p>
                        <span class="price d-block">₹ {{ numberFormat(data.price) }}</span>
                      </label>
                    </div>
                    <template
                      slot="nextArrow"
                      slot-scope="{next}"
                      >
                      <div class="custom-navigation" @click="next">
                        <div class="navigation">
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="20"
                          />
                        </div>
                      </div>
                    </template>
                  </VueSlickCarousel>
                  
                  <b-button :disabled="!buyPackage" style="width: 100%;" class="btn btn-primary btn-buy-now pill mt-1" :href="buyPackage" >BUY NOW</b-button>
                </b-card-body>
              </b-card>
            </div>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
  
<script>
import { ref, onMounted, inject, watch } from "@vue/composition-api";
import InputField from "@/views/components/form/InputField.vue";
import SelectField from "@/views/components/form/SelectField.vue";
import DateField from "@/views/components/form/DateField.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Vue from "vue";
import router from '@/router'
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

/* eslint-disable global-require */
export default {
  components: {
    SelectField,
    InputField,
    DateField,
    VueSlickCarousel
  },
  setup() {
    const $http = inject("$http");
    const tab = ref("Packages");
    const mainTab = [
      { id: 1, title: "Packages" },
      { id: 2, title: "Advisors" },
      { id: 3, title: "Courses" },
    ];
    const filters = ref({})
    const search = ref(null)
    const sliderSetting = {
      "arrows": true,
      "speed": 500,
      "slidesToShow": 4,
      "slidesToScroll": 4,
      "responsive": [
          {
            "breakpoint": 1441,
            "settings": {
              "arrows":true,
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 1024,
            "settings": {
               "arrows":true,
              "slidesToShow": 4,
              "slidesToScroll": 4,
            }
          },
          {
            "breakpoint": 768,
            "settings": {
               "arrows":true,
              "slidesToShow": 3,
              "slidesToScroll": 3,
            }
          },
          {
            "breakpoint": 425,
            "settings": {
               "arrows":true,
              "slidesToShow": 2,
              "slidesToScroll": 2
            }
          },
          {
            "breakpoint": 375,
            "settings": {
               "arrows":true,
              "slidesToShow": 1,
              "slidesToScroll": 1
            }
          }
        ]
    }

    let packages = ref([]);
    const buyPackage= ref(null)
    const featuresLength = ref(4)
    onMounted(() => {
      getPackage();
    });

    let selectedSegment = ref("");
    let selectedAdvisor = ref("");

    watch(selectedSegment, val => {
      getPackage();
    }, {deep: true})

    const getPackage = async () => {
      
      await $http
        .get(`advisor/package/list?segment=${selectedSegment.value}`)
        .then((response) => {
          packages.value = response.data.packages;
          // filters.value =  response.data.filters
        })
        .catch((error) => {
          Vue.$toast({
            component: ToastificationContent,
            props: {
              title: `Error`,
              icon: "AlertCircleIcon",
              variant: "danger",
              text: error.response,
              closeOnClick: false,
              timeout: false,
            },
          });
        });
    };

    const numberFormat = (number, decimals, decimalSeparator, thousandsSeparator) => {
      decimals = decimals || 0;
      decimalSeparator = decimalSeparator || '.';
      thousandsSeparator = thousandsSeparator || ',';

      number = parseFloat(number);

      if (!isFinite(number) || isNaN(number)) {
          return '';
      }

      var fixed = number.toFixed(decimals);
      var parts = fixed.split('.');
      var integerPart = parts[0];
      var decimalPart = parts.length > 1 ? decimalSeparator + parts[1] : '';

      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandsSeparator);

      return integerPart + decimalPart;
    }


    return {
      packages,
      mainTab,
      tab,
      filters,
      search,
      buyPackage,
      featuresLength,
      selectedAdvisor,
      selectedSegment,
      numberFormat,
      sliderSetting
    };
  },
};
</script>

<style lang="scss">
.stock-advisor{
  background: #FFFFFF;
  min-height: 100%;

  .package-filter-card {
    box-shadow: none;
    border: 1px solid #07143714;
  }

  .tab-card .nav-tabs {
    text-transform: capitalize;
  }
  
  .case-badge {
    border-radius: 4px;
    font-size: 14px;
    background: rgba(37, 99, 235, 0.12);
    color: #404966;
    line-height: 18px;
    height: 26px;
  }
  
  .price {
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    margin-top: 6px;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #252F4A;
  }
  .packages-card{
    box-shadow: none;
    border: 1px solid #07143714;

    .card-header{
      background: rgba(246, 250, 255, 1);
      padding: 1.5rem !important;
    }
    .card-body{
      padding: 0  !important;
    }

    .div-badge{
      min-width: 135px;
    }

    .packages-card-body{
      padding: 1.5rem !important;
      .details-section{
        p{
          font-size: 12px;
        }
        span{
          font-size: 14px;
        }
      }
      .details-section-gap{
        border-right: 1px solid #07143714 ;
        @media (max-width: 425px) {
          margin: 8px 0;
          height: 1px;
          border-bottom: 1px solid #07143714 ;
        }
      }
    }
    .sub-title{
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #0714378F;
      padding-top: 6px;
      // word-break: break-all;
    }
    .main-title{
      font-size: 16px !important;
    }
    .badge-segment{
      font-size: 11px !important;
      background-color: rgba(27, 132, 255, 0.04);
      color: #1B84FF;
    }
    .badge-light-danger {
      font-size: 11px !important;
      background-color: rgba(248, 40, 90, 0.04);
      color: #F8285A;
    }
    .badge-light-warning {
      font-size: 11px !important;
      background-color: rgba(246, 192, 0, 0.04);
      color: #F6C000;
    }
    .badge-light-success {
      font-size: 11px !important;
      background-color: rgba(23, 198, 83, 0.04);
      color: #17C653;
    }
    .active-status{ 
      padding: 5px 10px !important;
    }
    .carousel-container{
      margin: 0 20px;
    }
    .carousel-container .item{
      cursor:pointer;
      
      margin: 0 auto;
      text-align: center;
    }
    .carousel-container .item label{
      min-width: 113px;
      cursor:pointer;
      border-radius: 4px;
      border: 1px solid #07143729;
      padding: 10px 15px;
      color: #252F4A;
      &.active-item{
        border: 1px solid #1B84FF !important;
        color: #1B84FF;
      }
      p{
        font-size: 14px;
      }
      span{
        font-size: 16px;
      }
    }
    .slick-next:before,.slick-prev:before{
      display: none !important;
    }
    .slick-next {
      right: -14px !important;
    }
    .carousel-container .navigation{
      width: 24px;
      height: 24px;
      left: 4px;
      border: 1px solid #07143714;
      border-radius: 50%;
      margin: 0 auto;
      line-height: 20px;
      color: #99A1B7;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
    
    .cat-carousel__navigation--end{
      display: block !important;
    }
    .show-less-more{
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      color: #1B84FF;
      cursor: pointer;
      text-decoration: underline;
    }
    .btn-buy-now {
      background-color: #1B84FF !important;
    }
  }
}

</style>